import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useSpring, animated } from 'react-spring';
import {
  Star,
  Clock,
  Leaf,
  Beef,
  Play,
  Volume2,
  VolumeX,
} from 'lucide-react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Switch } from './ui/switch';
import loadingAnimation from '../assets/Loading.json';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from './ui/card';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

// Import SVG icons from assets folder
import zomatoSvg from '../assets/zomato.svg';
import swiggySvg from '../assets/swiggy.svg';
import instagramSvg from '../assets/instagram.svg';
import directionSvg from '../assets/direction.svg';
import callSvg from '../assets/call.svg';

// Animation Variants
const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeOut' } },
  exit: { opacity: 0, y: -50, transition: { duration: 0.3 } },
};

const profileCardVariants = {
  hidden: { opacity: 0, scale: 0.95 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.6, ease: 'easeOut' } },
};

// String Curtain Animation Component
const StringCurtain = () => {
  const stringCount = 50;
  const strings = Array.from({ length: stringCount }, (_, index) => ({
    id: index,
    delay: Math.random() * 2,
    duration: 2 + Math.random() * 2,
  }));

  const stringVariants = {
    initial: { y: 0, rotate: 0 },
    animate: (i) => ({
      y: [0, -10, 0],
      rotate: [0, Math.random() * 10 - 5, 0],
      transition: {
        y: {
          repeat: Infinity,
          repeatType: 'loop',
          duration: strings[i].duration,
          delay: strings[i].delay,
          ease: 'easeInOut',
        },
        rotate: {
          repeat: Infinity,
          repeatType: 'loop',
          duration: strings[i].duration,
          delay: strings[i].delay,
          ease: 'easeInOut',
        },
      },
    }),
  };

  return (
    <div className="absolute bottom-0 left-0 right-0 h-20 overflow-hidden pointer-events-none">
      <div className="flex justify-between h-full">
        {strings.map((string, index) => (
          <motion.div
            key={string.id}
            className="w-1 bg-gradient-to-b from-yellow-400 to-yellow-600"
            style={{
              height: '100%',
              transformOrigin: 'bottom center',
            }}
            variants={stringVariants}
            initial="initial"
            animate="animate"
            custom={index}
          />
        ))}
      </div>
    </div>
  );
};

const VideoCard = React.memo(({ videoUrl, thumbnailUrl, item, sortedMenuItems }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    controls.start('visible');
  }, [controls]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsPlaying(entry.isIntersecting),
      { threshold: 0.5 }
    );
    if (videoRef.current) observer.observe(videoRef.current);
    return () => {
      if (videoRef.current) observer.unobserve(videoRef.current);
    };
  }, []);

  const handleMuteToggle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsMuted(!isMuted);
  };
  const handlePlayClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsPlaying(true);
  };

  const getVideoId = (url) => {
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([^?&]+)/);
    return match ? match[1] : '';
  };

  return (
    <motion.div
      ref={videoRef}
      className="relative w-full h-full"
      animate={controls}
      variants={cardVariants}
    >
      <div className="relative w-full" style={{ paddingTop: '100%' }}>
        <div className="absolute top-0 left-0 w-full h-full bg-black rounded-t-lg overflow-hidden shadow-lg">
          {!isPlaying ? (
            <motion.div
              className="w-full h-full bg-cover bg-center cursor-pointer relative"
              style={{ backgroundImage: `url(${thumbnailUrl})` }}
              onClick={handlePlayClick}
              whileHover={{ scale: 1.02 }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                <Play className="w-16 h-16 text-white" />
              </div>
            </motion.div>
          ) : (
            <div className="relative w-full h-full overflow-hidden pointer-events-none">
              <iframe
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[177.78%] h-[177.78%]"
                src={`${videoUrl}?autoplay=1&mute=${isMuted ? 1 : 0}&loop=1&playlist=${getVideoId(videoUrl)}&modestbranding=1&rel=0&iv_load_policy=3&enablejsapi=1`}
                allow="autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="item-video"
              />
            </div>
          )}

          <motion.div
            className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black/70 to-transparent z-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <div className="flex items-center justify-between">
              <motion.button
                onClick={handleMuteToggle}
                className="p-2 text-white rounded-full hover:bg-black/20"
                whileHover={{ scale: 1.1 }}
              >
                {isMuted ? <VolumeX className="w-5 h-5" /> : <Volume2 className="w-5 h-5" />}
              </motion.button>
            </div>
          </motion.div>
        </div>
        <div 
          className="absolute inset-0 z-5"
          style={{ touchAction: 'pan-y' }}
        />
      </div>
    </motion.div>
  );
});

const ListViewCard = React.memo(({ item, handleToggleSpecial, sortedMenuItems }) => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start('visible');
  }, [controls]);

  const index = sortedMenuItems.findIndex((menuItem) => menuItem.id === item.id);
  const discount = index < 10 ? 0.4 : index < 20 ? 0.2 : 0.1;
  const originalPrice = item.price;
  const inflatedPrice = Math.round(originalPrice / (1 - discount));

  return (
    <motion.div
      className={`bg-white border rounded-lg shadow-md p-4 flex flex-col space-y-2 ${
        item.special ? 'border-red-500' : 'border-gray-200'
      }`}
      variants={cardVariants}
      animate={controls}
      whileHover={{ scale: 1.02, boxShadow: '0 10px 20px rgba(0,0,0,0.1)' }}
    >
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-lg font-bold flex items-center space-x-2">
            <span>{item.name}</span>
            {item.dietType === 'Vegetarian' ? (
              <Leaf className="w-4 h-4 text-green-500" />
            ) : (
              <Beef className="w-4 h-4 text-red-500" />
            )}
          </h2>
          {item.special && (
            <motion.div
              className="flex items-center space-x-1 mt-1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <Star className="w-5 h-5 text-red-500" fill="currentColor" />
              <span className="text-sm text-red-600 font-bold">Today's Special</span>
            </motion.div>
          )}
        </div>
        <div className="text-right">
          <p className="text-sm">
            <span className="text-gray-500 line-through">₹{inflatedPrice}</span>
            <span className="text-purple-600 font-bold ml-1"> ({discount * 100}% off)</span>
          </p>
          <p className="text-xl font-bold text-blue-600">₹{originalPrice}</p>
        </div>
      </div>

      <p className="text-gray-500">{item.cuisine} Cuisine</p>

      <div className="flex items-center space-x-2">
        <Star className="h-5 w-5 text-yellow-400" />
        <span>{item.rating}</span>
        <Clock className="h-5 w-5 text-gray-400 ml-2" />
        <span>{item.prepTime}</span>
      </div>
    </motion.div>
  );
});

const RestaurantApp = () => {
  const { id, tableNumber: paramTableNumber } = useParams();
  const [tableNumber, setTableNumber] = useState(paramTableNumber || localStorage.getItem('tableNumber') || null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedDietType, setSelectedDietType] = useState('All');
  const [selectedCuisine, setSelectedCuisine] = useState('All');
  const [restaurantData, setRestaurantData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isListView, setIsListView] = useState(true);
  const [categories, setCategories] = useState(['All']);
  const [cuisines, setCuisines] = useState(['All']);

  const dummyLinks = {
    zomato: 'https://link.zomato.com/xqzv/rshare?id=412679722a4b995e&fbclid=PAY2xjawJfQ21leHRuA2FlbQIxMQABpyUyiJPXP2_CruQocBgfwNLpNL18sEYEbs2SQOi2pQcqb5zFS_fFYYbQy8-N_aem_1RLPo42h_iMVXT8LQRhqPA',
    swiggy: 'https://www.swiggy.com/dummy-restaurant',
    instagram: 'https://www.instagram.com/foodexxrestaurant',
    directions: 'https://maps.app.goo.gl/RFRsvGrW6zHd387K6',
    phone: '+911234567890'
  };

  const backgroundProps = useSpring({
    from: { backgroundPosition: '0% 0%' },
    to: { backgroundPosition: '100% 100%' },
    config: { duration: 10000 },
    loop: true,
  });

  useEffect(() => {
    if (paramTableNumber) {
      setTableNumber(paramTableNumber);
      localStorage.setItem('tableNumber', paramTableNumber);
    }
  }, [paramTableNumber]);

  useEffect(() => {
    const fetchRestaurantData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(
          `${BASE_URL}/api/public/restaurant-data?id=${id}&tableNumber=${tableNumber}`
        );
        if (!response.ok) throw new Error('Failed to fetch restaurant data');
        const data = await response.json();
        if (data && Object.keys(data).length > 0) {
          setRestaurantData(data);
          setCategories(['All', ...new Set(data.menu.map((item) => item.category))]);
          setCuisines(['All', ...new Set(data.menu.map((item) => item.cuisine))]);
          if (tableNumber) localStorage.setItem('tableNumber', tableNumber);
        } else {
          setIsError(true);
        }
      } catch (error) {
        console.error('Error fetching restaurant data:', error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    if (id) fetchRestaurantData();
  }, [id, tableNumber]);

  const sortedMenuItems = useMemo(() => {
    if (!restaurantData) return [];
    return [...restaurantData.menu].sort((a, b) => b.price - a.price);
  }, [restaurantData]);

  const handleToggleSpecial = (itemId) => {
    setRestaurantData((prevData) => {
      if (!prevData) return null;
      const updatedMenu = prevData.menu.map((menuItem) =>
        menuItem.id === itemId ? { ...menuItem, special: !menuItem.special } : menuItem
      );
      return { ...prevData, menu: updatedMenu };
    });
  };

  const filteredMenuItems = useMemo(() => {
    if (!restaurantData) return [];
    return restaurantData.menu
      .filter((item) => {
        const matchesCategory = selectedCategory === 'All' || item.category === selectedCategory;
        const matchesCuisine = selectedCuisine === 'All' || item.cuisine === selectedCuisine;
        const matchesDietType = selectedDietType === 'All' || item.dietType === 'Vegetarian';
        return matchesCategory && matchesCuisine && matchesDietType;
      })
      .sort((a, b) => b.special - a.special);
  }, [restaurantData, selectedCategory, selectedCuisine, selectedDietType]);

  const renderProfileCard = () => (
    <motion.div
      className="max-w-7xl mx-auto px-4 py-6"
      variants={profileCardVariants}
      initial="hidden"
      animate="visible"
    >
      <Card className="bg-white/80 backdrop-blur-md shadow-xl rounded-xl overflow-hidden border border-gray-100/50 relative">
        <div className="relative h-56 bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500">
          <img
            src={restaurantData.logo}
            alt={`${restaurantData.name} Banner`}
            className="absolute inset-0 w-full h-full object-cover opacity-20 mix-blend-overlay"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <motion.img
              src={restaurantData.logo}
              alt={`${restaurantData.name} Logo`}
              className="h-28 w-28 rounded-full border-4 border-white shadow-lg object-cover"
              whileHover={{ scale: 1.1, rotate: 5 }}
              transition={{ type: 'spring', stiffness: 300 }}
            />
          </div>
          <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent" />
        </div>

        <CardContent className="p-6 relative z-10">
          <h2 className="text-3xl font-extrabold text-center text-gray-900 mb-3 tracking-tight">
            {restaurantData.name}
            <span className="block h-1 w-24 bg-gradient-to-r from-indigo-500 to-pink-500 mx-auto mt-2 rounded-full" />
          </h2>

          <p className="text-gray-600 text-center mb-6 text-sm leading-relaxed max-w-md mx-auto">
            {restaurantData.bio || 'A delightful place to enjoy exquisite cuisine with a modern twist.'}
          </p>

          <div className="flex justify-center space-x-6 mb-6">
            {[
              { link: dummyLinks.zomato, svg: zomatoSvg, color: 'text-red-500 hover:text-red-600' },
              { link: dummyLinks.swiggy, svg: swiggySvg, color: 'text-orange-500 hover:text-orange-600' },
              { link: dummyLinks.instagram, svg: instagramSvg, color: 'text-pink-500 hover:text-pink-600' },
              { link: dummyLinks.directions, svg: directionSvg, color: 'text-blue-500 hover:text-blue-600' },
              { link: dummyLinks.phone, svg: callSvg, color: 'text-green-500 hover:text-green-600' },
            ].map((item, index) => (
              <motion.a
                key={index}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className={`${item.color} p-2 bg-white/10 backdrop-blur-sm rounded-full shadow-md transition-all duration-300`}
                whileHover={{ scale: 1.2, boxShadow: '0 0 15px rgba(0,0,0,0.2)' }}
                whileTap={{ scale: 0.95 }}
              >
                <img src={item.svg} alt="" className="w-6 h-6" />
              </motion.a>
            ))}
          </div>

          <motion.div
            className="flex items-center justify-center space-x-2 text-gray-700 bg-white/50 backdrop-blur-sm py-2 px-4 rounded-full shadow-inner"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <Clock className="w-5 h-5 text-indigo-500" />
            <span className="text-sm font-medium">
              {restaurantData.hours || 'Mon-Sun: 11:00 AM - 11:00 PM'}
            </span>
          </motion.div>
        </CardContent>
      </Card>
    </motion.div>
  );

  const renderMainContent = () => (
    <>
      {renderProfileCard()}

      <div className="max-w-7xl mx-auto px-4">
        <div className="relative h-20" style={{ marginTop: '-1.5rem' }}>
          <StringCurtain />
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-6 mt-6">
        <motion.div
          className="flex items-center justify-between"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <motion.div
            className="flex items-center space-x-2 px-3 py-2 rounded-lg shadow-sm bg-white/80 backdrop-blur-sm"
            style={{ minWidth: '120px' }}
            whileHover={{ scale: 1.05 }}
          >
            <span
              className={`text-sm font-medium ${
                selectedDietType === 'All' ? 'text-black' : 'text-black-400'
              }`}
            >
              🟢🔴
            </span>
            <Switch
              checked={selectedDietType === 'Vegetarian'}
              onCheckedChange={(checked) => setSelectedDietType(checked ? 'Vegetarian' : 'All')}
            />
            <span
              className={`text-sm font-medium ${
                selectedDietType === 'Vegetarian' ? 'text-black' : 'text-gray-400'
              }`}
            >
              🟢
            </span>
          </motion.div>

          <motion.div
            className="flex items-center space-x-2 px-3 py-2 rounded-lg shadow-sm bg-white/80 backdrop-blur-sm"
            style={{ minWidth: '100px' }}
            whileHover={{ scale: 1.05 }}
          >
            <span className="text-sm font-medium text-gray-700" style={{ display: 'inline-block', width: '20px', textAlign: 'center' }}>{isListView ? '☰' : '🎬'}</span>
            <Switch checked={isListView} onCheckedChange={(checked) => setIsListView(checked)} />
          </motion.div>
        </motion.div>
      </div>

      <div className="max-w-7xl mx-auto px-4 space-y-4 flex flex-col items-center mt-4">
        <motion.div
          className="bg-white p-4 w-full md:w-3/4 lg:w-2/3 mx-auto space-y-6 bg-opacity-80 backdrop-blur-sm rounded-lg"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <div className="w-full overflow-x-auto pb-2">
            <div className="inline-flex gap-4">
              {categories.map((category) => (
                <motion.button
                  key={category}
                  onClick={() => setSelectedCategory(category)}
                  className={`px-6 py-2 rounded-full text-sm font-medium whitespace-nowrap ${
                    selectedCategory === category
                      ? 'bg-black text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {category}
                </motion.button>
              ))}
            </div>
          </div>

          <div className="w-full overflow-x-auto pb-2">
            <div className="inline-flex gap-4">
              {cuisines.map((cuisine) => (
                <motion.button
                  key={cuisine}
                  onClick={() => setSelectedCuisine(cuisine)}
                  className={`px-6 py-2 rounded-full text-sm font-medium whitespace-nowrap ${
                    selectedCuisine === cuisine
                      ? 'bg-black text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {cuisine}
                </motion.button>
              ))}
            </div>
          </div>
        </motion.div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-8">
        <AnimatePresence mode="wait">
          {isListView ? (
            <motion.div
              key="list"
              className="space-y-4"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{ duration: 0.5 }}
            >
              {filteredMenuItems.map((item) => (
                <ListViewCard
                  key={item.id}
                  item={item}
                  handleToggleSpecial={handleToggleSpecial}
                  sortedMenuItems={sortedMenuItems}
                />
              ))}
            </motion.div>
          ) : (
            <motion.div
              key="swiper"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
            >
              <Swiper
                spaceBetween={16}
                slidesPerView={1}
                breakpoints={{ 640: { slidesPerView: 1.5 }, 1024: { slidesPerView: 2.5 } }}
              >
                {filteredMenuItems.map((item) => {
                  const index = sortedMenuItems.findIndex((menuItem) => menuItem.id === item.id);
                  const discount = index < 10 ? 0.4 : index < 20 ? 0.2 : 0.1;
                  const originalPrice = item.price;
                  const inflatedPrice = Math.round(originalPrice / (1 - discount));

                  return (
                    <SwiperSlide key={item.id}>
                      <Card className="overflow-hidden hover:shadow-lg transition-shadow h-full">
                        <VideoCard
                          videoUrl={item.videoUrl}
                          thumbnailUrl={item.thumbnailUrl}
                          item={item}
                          sortedMenuItems={sortedMenuItems}
                        />
                        <CardHeader>
                          <div className="flex justify-between items-start">
                            <div>
                              <div className="flex items-center space-x-2">
                                <CardTitle className="text-xl font-bold">{item.name}</CardTitle>
                                {item.dietType === 'Vegetarian' ? (
                                  <Leaf className="w-4 h-4 text-green-500" />
                                ) : (
                                  <Beef className="w-4 h-4 text-red-500" />
                                )}
                              </div>

                              {item.special && (
                                <motion.div
                                  className="flex items-center space-x-1 mt-2"
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                >
                                  <Star className="w-4 h-4 text-red-500" fill="currentColor" />
                                  <span className="text-sm text-red-500 font-bold">Today's Special</span>
                                </motion.div>
                              )}

                              <div className="text-sm text-gray-500 mt-1">{item.cuisine} Cuisine</div>
                              <div className="mt-2">
                                <p className="text-sm">
                                  <span className="text-gray-500 line-through">₹{inflatedPrice}</span>
                                  <span className="text-purple-600 font-bold ml-1"> ({discount * 100}% off)</span>
                                </p>
                                <span className="text-xl font-bold text-blue-600">₹{originalPrice}</span>
                              </div>
                              <div className="flex items-center space-x-2 mt-4">
                                <Star className="h-5 w-5 text-yellow-400 fill-current" />
                                <span className="text-sm text-gray-600">{item.rating}</span>
                                <Clock className="h-5 w-5 text-gray-400 ml-2" />
                                <span className="text-sm text-gray-600">{item.prepTime}</span>
                              </div>
                            </div>
                          </div>
                        </CardHeader>
                      </Card>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );

  return (
    <animated.div
      className="min-h-screen bg-gray-100 relative"
      style={{
        background: 'linear-gradient(45deg, #f3f4f6, #e5e7eb)',
        ...backgroundProps,
      }}
    >
      <AnimatePresence>
        {isLoading ? (
          <motion.div
            key="loading"
            className="flex items-center justify-center h-screen bg-gray-100"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <Player autoplay loop src={loadingAnimation} style={{ height: '100px', width: '100px' }} />
          </motion.div>
        ) : isError ? (
          <motion.div
            key="error"
            className="flex items-center justify-center h-screen text-red-500"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            Restaurant not found
          </motion.div>
        ) : (
          <div key="main-content">{renderMainContent()}</div>
        )}
      </AnimatePresence>
    </animated.div>
  );
};

export default RestaurantApp;