import React, { useEffect } from "react";
import { Card, CardHeader, CardContent } from "./ui/card";
import { ArrowLeft } from "lucide-react";

const PricingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 py-6 px-4 sm:px-6 lg:px-8">
      {/* Back Button */}
      <div className="mb-6 max-w-4xl mx-auto">
        <button
          className="flex items-center gap-2 bg-white text-gray-700 rounded-full px-4 py-2 shadow-sm hover:bg-gray-50 hover:shadow-md transition-all duration-300"
          onClick={() => window.history.back()}
        >
          <ArrowLeft className="h-4 w-4" />
          <span className="text-sm font-medium">Back</span>
        </button>
      </div>

      {/* Hero Section */}
      <div className="max-w-4xl mx-auto text-center mb-10 px-4 py-8 bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 rounded-xl shadow-lg text-white">
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-3">
          Find Your Perfect Restaurant Plan
        </h1>
        <p className="text-base sm:text-lg text-gray-100 mb-4">
          Elevate your business with Merstak Web's tailored solutions
        </p>
        <p className="text-sm italic text-yellow-100">
          Limited Time: 20% off your first month!
        </p>
      </div>

      {/* Pricing Cards */}
      <div className="max-w-4xl mx-auto grid gap-6 md:grid-cols-3">
        {/* Free Plan */}
        <Card className="flex flex-col rounded-xl shadow-md hover:shadow-lg transition-all duration-300">
          <CardHeader className="bg-gray-50 rounded-t-xl">
            <h2 className="text-xl font-semibold text-gray-700">Free</h2>
            <p className="text-2xl font-bold text-gray-800">
              ₹0<span className="text-sm font-normal">/mo</span>
            </p>
            <p className="text-xs text-gray-500">Basic features to start</p>
          </CardHeader>
          <CardContent className="flex-1 p-4">
            <ul className="text-sm text-gray-600 space-y-2">
              <li>✔️ QR Code + Orders <span className="text-red-500 text-xs">(With Ads)</span></li>
              <li>✔️ 5% Commission</li>
              <li>✔️ 30s Delay</li>
              <li>✔️ Text Menu</li>
              <li>✔️ 50 Orders/Day</li>
              <li className="text-gray-400 line-through">❌ Lottery</li>
              <li className="text-gray-400 line-through">❌ Analytics</li>
            </ul>
            <button className="mt-4 w-full bg-gray-200 text-gray-700 py-2 rounded-full text-sm font-medium hover:bg-gray-300 transition">
              Start Free
            </button>
          </CardContent>
        </Card>

        {/* Growth Plan */}
        <Card className="flex flex-col rounded-xl shadow-lg border-2 border-green-500 bg-green-50 relative overflow-hidden hover:shadow-xl transition-all duration-300">
          <div className="absolute top-0 left-0 w-full bg-green-500 text-white text-xs font-semibold text-center py-1">
            Most Popular – 80% Choice
          </div>
          <CardHeader className="bg-green-100 rounded-t-xl pt-8">
            <h2 className="text-xl font-semibold text-green-700">Growth</h2>
            <p className="text-2xl font-bold text-green-800">
              ₹600<span className="text-sm font-normal">/mo</span>
            </p>
            <p className="text-xs text-green-600">Ad-free growth</p>
          </CardHeader>
          <CardContent className="flex-1 p-4">
            <ul className="text-sm text-gray-700 space-y-2">
              <li>✔️ QR Code + Orders <span className="text-green-500 text-xs">(No Ads)</span></li>
              <li>✔️ 5% Commission</li>
              <li>✔️ Instant Processing</li>
              <li>✔️ Text + Video Menu</li>
              <li>✔️ Unlimited Orders</li>
              <li>✔️ Lottery Feature</li>
              <li>✔️ Analytics</li>
              <li>✔️ Loyalty Program</li>
            </ul>
            <button className="mt-4 w-full bg-green-500 text-white py-2 rounded-full text-sm font-medium hover:bg-green-600 transition">
              Get Growth
            </button>
          </CardContent>
        </Card>

        {/* Premium Plan */}
        <Card className="flex flex-col rounded-xl shadow-md hover:shadow-lg transition-all duration-300">
          <CardHeader className="bg-indigo-50 rounded-t-xl">
            <h2 className="text-xl font-semibold text-indigo-700">Premium</h2>
            <p className="text-2xl font-bold text-indigo-800">
              ₹1500<span className="text-sm font-normal">/mo</span>
            </p>
            <p className="text-xs text-indigo-600">Top-tier features</p>
          </CardHeader>
          <CardContent className="flex-1 p-4">
            <ul className="text-sm text-gray-700 space-y-2">
              <li>✔️ QR Code + Orders <span className="text-green-500 text-xs">(No Ads)</span></li>
              <li>✔️ 0% Commission</li>
              <li>✔️ Instant Processing</li>
              <li>✔️ Full Media Menu</li>
              <li>✔️ Unlimited Orders</li>
              <li>✔️ Enhanced Lottery</li>
              <li>✔️ Advanced Analytics</li>
              <li>✔️ Custom QR</li>
            </ul>
            <button className="mt-4 w-full bg-indigo-500 text-white py-2 rounded-full text-sm font-medium hover:bg-indigo-600 transition">
              Go Premium
            </button>
          </CardContent>
        </Card>
      </div>

      {/* Footer CTA */}
      <div className="max-w-4xl mx-auto mt-10 px-6 py-8 bg-gray-900 text-white rounded-xl text-center">
        <h2 className="text-xl sm:text-2xl font-bold mb-3">
          Elevate Your Restaurant Today
        </h2>
        <p className="text-sm sm:text-base mb-4">
          Join the Merstak Web revolution!
        </p>
        <button className="bg-green-500 text-white py-2 px-6 rounded-full text-sm font-medium hover:bg-green-600 transition">
          Start with Growth
        </button>
      </div>
    </div>
  );
};

export default PricingPage;