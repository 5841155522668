import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { toast } from 'react-toastify';
import { X, Plus, Minus, ShoppingBag, ArrowLeft } from 'lucide-react';
import Lottie from 'react-lottie-player';
import loadingAnimation from '../assets/waiter.json';
import Lottery from './Lottery';
import BihuGif from '../assets/Bihu.jpg'; // Adjust path as needed

const baseUrl = process.env.REACT_APP_API_BASE_URL;

// CartItem component (unchanged)
const CartItem = React.memo(({ item, handleQuantityChange, handleRemoveItem }) => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  return (
    <motion.div
      className="bg-white/90 backdrop-blur-md rounded-xl p-4 shadow-md flex items-center justify-between"
      animate={controls}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.3 }}
    >
      <div className="flex-1">
        <h3 className="text-sm font-semibold text-gray-800">{item.name}</h3>
        <p className="text-xs text-gray-500">₹{item.price.toFixed(2)} x {item.quantity}</p>
      </div>
      <div className="flex items-center space-x-2">
        <motion.button
          onClick={() => handleQuantityChange(item.id, item.quantity - 1)}
          className="p-1 bg-gray-200 rounded-full"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Minus size={16} />
        </motion.button>
        <span className="text-sm font-bold text-indigo-600">{item.quantity}</span>
        <motion.button
          onClick={() => handleQuantityChange(item.id, item.quantity + 1)}
          className="p-1 bg-gray-200 rounded-full"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Plus size={16} />
        </motion.button>
        <motion.button
          onClick={() => handleRemoveItem(item.id, item.name)}
          className="p-1 bg-red-500 text-white rounded-full"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <X size={16} />
        </motion.button>
      </div>
    </motion.div>
  );
});

// SuggestedItemCard component (unchanged)
const SuggestedItemCard = React.memo(({ item, addToCart }) => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start({ opacity: 1, y: 0 });
  }, [controls]);

  const originalPrice = item.price;
  const inflatedPrice = Math.round(originalPrice / (1 - 0.4));

  return (
    <motion.div
      className="bg-white/90 backdrop-blur-md rounded-xl p-3 shadow-md flex items-center justify-between"
      animate={controls}
      transition={{ duration: 0.3 }}
      whileHover={{ scale: 1.02 }}
    >
      <div className="flex items-center space-x-3">
        <div>
          <h3 className="text-sm font-semibold text-gray-800 flex items-center space-x-1">
            <span>{item.name}</span>
            {item.dietType === 'Vegetarian' ? (
              <span className="text-green-500">🟢</span>
            ) : (
              <span className="text-red-500">🔴</span>
            )}
          </h3>
          <p className="text-xs text-gray-500">{item.cuisine}</p>
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <div className="text-right">
          <p className="text-xs">
            <span className="text-gray-400 line-through">₹{inflatedPrice}</span>
            <span className="text-purple-600 font-bold ml-1">(40% off)</span>
          </p>
          <p className="text-sm font-bold text-indigo-600">₹{originalPrice}</p>
        </div>
        <motion.button
          onClick={() => addToCart(item, 1)}
          className="p-2 bg-indigo-500 text-white rounded-full"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Plus size={16} />
        </motion.button>
      </div>
    </motion.div>
  );
});

const MyCart = ({ cartItems, onPlaceOrder, updateCartItem, setViewCart, userId, tableNumber, menuItems, addToCart }) => {
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);
  const [showWaiterPopup, setShowWaiterPopup] = useState(false);
  const [timer, setTimer] = useState(120);
  const [hotOfferTimer, setHotOfferTimer] = useState(300);
  const [showLottery, setShowLottery] = useState(false);

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  const handleQuantityChange = (itemId, quantity) => {
    if (quantity < 1) return;
    updateCartItem(itemId, quantity);
    toast.info(`Quantity updated to ${quantity}`);
  };

  const handleRemoveItem = (itemId, itemName) => {
    updateCartItem(itemId, 0);
    toast.info(`${itemName} removed from cart.`);
  };

  const handlePlaceOrder = async () => {
    if (cartItems.length === 0) {
      toast.error('Your cart is empty!');
      return;
    }
    setIsPlacingOrder(true);
    try {
      if (!tableNumber) console.warn("⚠️ No table number found, defaulting to takeaway order.");
      await onPlaceOrder(userId, tableNumber);
      toast.success('Order placed successfully!');
    } catch (error) {
      toast.error('Failed to place order. Please try again.');
    } finally {
      setIsPlacingOrder(false);
    }
  };

  const handleCallWaiter = async () => {
    if (!userId || !tableNumber) {
      toast.error('Unable to call waiter: Missing user ID or table number.');
      return;
    }

    try {
      const response = await fetch(`${baseUrl}/api/waiter-calls`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          tableNumber,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to call waiter');
      }

      const result = await response.json();
      console.log('Waiter call response:', result);
      toast.success('Waiter has been notified!');
      setShowWaiterPopup(true);
      setTimer(120);
    } catch (error) {
      console.error('Error calling waiter:', error);
      toast.error('Failed to notify waiter. Please try again.');
    }
  };

  const handleSpinNow = () => {
    setShowLottery(true);
  };

  useEffect(() => {
    if (showWaiterPopup && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      setShowWaiterPopup(false);
    }
  }, [showWaiterPopup, timer]);

  useEffect(() => {
    if (hotOfferTimer > 0) {
      const interval = setInterval(() => {
        setHotOfferTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [hotOfferTimer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const suggestedItems = useMemo(() => {
    if (!menuItems) return [];
    return menuItems
      .filter((item) => !cartItems.some((cartItem) => cartItem.id === item.id))
      .sort((a, b) => b.price - a.price)
      .slice(0, 2);
  }, [menuItems, cartItems]);

  return (
    <motion.div
className="fixed inset-0 bg-gradient-to-br from-black via-gray-900 to-black flex flex-col p-4 overflow-y-auto"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* Header */}
      <motion.div
        className="flex items-center justify-between mb-4"
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.4 }}
      >
        <h2 className="text-2xl font-bold text-white flex items-center">
          <ShoppingBag className="mr-2" size={24} /> My Cart
        </h2>
        <motion.button
          onClick={() => setViewCart(false)}
          className="p-2 bg-white/20 rounded-full text-white"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <ArrowLeft size={20} />
        </motion.button>
      </motion.div>

      {/* Cart Content */}
      <div className="flex-1 space-y-4">
        {cartItems.length === 0 ? (
          <motion.div
            className="flex flex-col items-center justify-center h-full text-white"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <p className="text-lg mb-4">Your cart is empty 🫶</p>
            <motion.button
              onClick={() => setViewCart(false)}
              className="px-6 py-3 bg-white text-indigo-600 rounded-full font-semibold"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Back to Menu
            </motion.button>
          </motion.div>
        ) : (
          <>
            {/* Cart Items */}
            <motion.div
              className="space-y-3"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <AnimatePresence>
                {cartItems.map((item) => (
                  <CartItem
                    key={item.id}
                    item={item}
                    handleQuantityChange={handleQuantityChange}
                    handleRemoveItem={handleRemoveItem}
                  />
                ))}
              </AnimatePresence>
            </motion.div>

            {/* Hot Offer Section (without Bihu Lottery) */}
            {hotOfferTimer > 0 && (
              <motion.div
                className="space-y-3"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold text-white">Hot Offer 🥵</h3>
                  <p className="text-sm text-white">Offer ends in: {formatTime(hotOfferTimer)}</p>
                </div>
                {suggestedItems.map((item) => (
                  <SuggestedItemCard key={item.id} item={item} addToCart={addToCart} />
                ))}
              </motion.div>
            )}
          </>
        )}
      </div>

      {/* Footer with Total and Actions */}
      {cartItems.length > 0 && (
        <>
          <motion.div
            className="bg-white/90 backdrop-blur-md rounded-t-xl p-4 shadow-lg mt-4"
            initial={{ y: 50 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.4 }}
          >
            <div className="flex justify-between items-center mb-4">
              <span className="text-lg font-semibold text-gray-800">Total:</span>
              <span className="text-xl font-bold text-indigo-800">₹{calculateTotal()}</span>
            </div>
            <div className="flex space-x-2">
              <motion.button
                onClick={handleCallWaiter}
                className="flex-1 px-4 py-2 rounded-lg text-white font-semibold bg-gradient-to-r from-red-500 to-red-700 hover:bg-blue-700"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Call Waiter 👋
              </motion.button>
              <motion.button
                onClick={handlePlaceOrder}
                disabled={false}
                className={`flex-1 px-4 py-2 rounded-lg text-white font-semibold ${
                  isPlacingOrder || true 
                    ? 'bg-gray-400 cursor-not-allowed' 
                    : 'bg-gradient-to-r from-indigo-500 to-indigo-700 hover:bg-indigo-700'
                }`}
                whileHover={{ scale: isPlacingOrder || true ? 1 : 1.05 }}
                whileTap={{ scale: isPlacingOrder || true ? 1 : 0.95 }}
              >
                {isPlacingOrder ? 'Placing Order...' : 'Place Order 🤤'}
              </motion.button>
            </div>
          </motion.div>

          {/* Bihu Lottery Section (Moved below Total card) */}
          {hotOfferTimer > 0 && (
            <motion.div
              className="mt-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              <div className="bg-white/0 backdrop-blur-md rounded-xl p-4 shadow-md">
                <img 
                  src={BihuGif} 
                  alt="Bihu Lottery" 
                  className="w-full max-w-[300px] mx-auto rounded-lg"
                />
                <motion.button
                  onClick={handleSpinNow}
                  className="mt-3 w-full px-4 py-2 bg-gradient-to-r from-indigo-500 to-indigo-700 text-white rounded-lg font-semibold"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Free Spin Now
                </motion.button>
              </div>
            </motion.div>
          )}
        </>
      )}

      {/* Waiter Call Popup */}
      <AnimatePresence>
        {showWaiterPopup && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center bg-black/50 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="bg-white rounded-xl p-6 shadow-lg relative flex flex-col items-center"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
            >
              <motion.button
                onClick={() => setShowWaiterPopup(false)}
                className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <X size={20} />
              </motion.button>
              <Lottie
                loop
                animationData={loadingAnimation}
                play
                style={{ width: 150, height: 150 }}
              />
              <p className="text-lg font-semibold text-gray-800 mt-4">Your waiter is coming</p>
              <p className="text-sm text-gray-600 mt-2">Time remaining: {formatTime(timer)}</p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Lottery Popup */}
      <AnimatePresence>
        {showLottery && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center bg-black/50 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="bg-white rounded-xl p-6 shadow-lg relative w-full max-w-md"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
            >
              <motion.button
                onClick={() => setShowLottery(false)}
                className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <X size={20} />
              </motion.button>
              <Lottery />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default MyCart;