// RestaurantDashboard.js  

import React, { useEffect, useState, useRef } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Switch } from './ui/switch';
import { Pencil, CheckCircle, XCircle, Copy, Download, Share2, Star, ChevronUp, ChevronDown } from 'lucide-react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from './ui/card';
import {
  Alert,
  AlertDescription,
} from './ui/alert';
import { QRCodeCanvas } from 'qrcode.react';
import newOrderSound from '../assets/NewOrderAssamese.mp3'; 
import repeatOrderSound from '../assets/RepeatOrderAssamese.mp3'; 
import waiterSound from '../assets/waiter.mp3'; // New audio file for waiter calls

const baseUrl = process.env.REACT_APP_API_BASE_URL;
console.log("Base URL:", baseUrl);

// Define the total number of tables for which QR codes should be generated
const totalTables = 10;

const RestaurantDashboard = () => {
  console.log("RestaurantDashboard component rendered");

  const [menuItems, setMenuItems] = useState([]);
  const [groupedOrders, setGroupedOrders] = useState([]);
  const [waiterCalls, setWaiterCalls] = useState([]); // New state for waiter calls
  const [error, setError] = useState('');
  const [isFrozen, setIsFrozen] = useState(false); 
  const [freezeErrorMessage, setFreezeErrorMessage] = useState('');
  const navigate = useNavigate();

  // New local state to track which orders and waiter calls have been accepted (waiterCallAcceptanceState kept but unused)
  const [orderAcceptanceState, setOrderAcceptanceState] = useState({});
  const [waiterCallAcceptanceState, setWaiterCallAcceptanceState] = useState({});

  // State for collapsing/expanding the Table QR Codes section
  const [isTableQRExpanded, setIsTableQRExpanded] = useState(false);

  // Audio and Previous Orders Refs
  const previousOrderIdsRef = useRef(new Set());
  const previousCustomersRef = useRef(new Set()); // Tracks WhatsApp numbers of previous customers
  const previousWaiterCallIdsRef = useRef(new Set()); // Tracks previous waiter call IDs
  const audioRefNew = useRef(new Audio(newOrderSound)); // Sound for new customers
  const audioRefRepeat = useRef(new Audio(repeatOrderSound)); // Sound for repeat customers
  const audioRefWaiter = useRef(new Audio(waiterSound)); // Sound for waiter calls

  // Set waiter audio to loop
  useEffect(() => {
    audioRefWaiter.current.loop = true;
  }, []);

  // Decode JWT token and extract userId with expiration check
  const getUserIdFromToken = () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      console.log("No token found. Redirecting to login...");
      setError("You are not logged in. Redirecting to login...");
      navigate("/restaurant-login");
      return null;
    }

    try {
      console.log("Attempting to decode token...");
      const decoded = jwtDecode(token);
      console.log("Decoded token:", decoded);

      if (decoded.exp * 1000 < Date.now()) {
        console.log("Token expired. Redirecting to login...");
        setError("Session expired. Redirecting to login...");
        localStorage.removeItem("authToken");
        navigate("/restaurant-login");
        return null;
      }

      console.log("Token is valid. User ID:", decoded.userId);
      localStorage.setItem("userId", decoded.userId);
      return decoded.userId;
    } catch (err) {
      console.error("Error decoding token:", err);
      setError("Invalid token. Redirecting to login...");
      localStorage.removeItem("authToken");
      navigate("/restaurant-login");
      return null;
    }
  };

  useEffect(() => {
    console.log("useEffect triggered in RestaurantDashboard");
    const userId = getUserIdFromToken();
    if (!userId) {
      console.log("No userId found, skipping fetchMenuItems");
      return;
    }

    console.log("Calling fetchMenuItems...");

    const fetchMenuItems = async () => {
      console.log("fetchMenuItems function triggered");
      const token = localStorage.getItem("authToken");
      console.log("Token retrieved from localStorage:", token);

      if (!token) {
        console.error("No token found in localStorage. Cannot fetch menu items.");
        setError("No valid authentication token found. Please log in again.");
        navigate("/restaurant-login");
        return;
      }

      try {
        console.log("Making API call to:", `${baseUrl}/api/menu`);
        console.log("Authorization Header Token:", `Bearer ${token}`);

        const response = await fetch(`${baseUrl}/api/menu`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        console.log("API response status:", response.status);

        if (response.status === 401) {
          console.error("Unauthorized: Token invalid or session expired.");
          setError("Session expired or unauthorized. Please log in again.");
          localStorage.removeItem("authToken");
          navigate("/restaurant-login");
          return;
        }

        if (!response.ok) {
          throw new Error("Failed to fetch menu items");
        }

        const data = await response.json();
        console.log("Menu items fetched:", data);
        setMenuItems(data);
      } catch (err) {
        console.error("Error fetching menu items:", err);
        setError("Failed to fetch menu items. Please try again later.");
      }
    };

    fetchMenuItems();
  }, [navigate]);

  // Fetch Freeze Status on Component Load
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.log("No userId found, skipping fetchFreezeStatus");
      return;
    }

    const fetchFreezeStatus = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/users/freeze-status/${userId}`);
        const data = await response.json();

        if (response.ok) {
          setIsFrozen(data.freeze); // Update freeze status
        } else {
          console.error('Failed to fetch freeze status:', data.message);
          setFreezeErrorMessage('Failed to check account status. Please try again.');
        }
      } catch (error) {
        console.error('Error fetching freeze status:', error);
        setFreezeErrorMessage('Failed to check account status. Please try again.');
      }
    };

    fetchFreezeStatus();
  }, []);

  // Auto-Refreshing Orders and Waiter Calls with Notification Sound
  useEffect(() => {
    console.log("Setting up orders and waiter calls polling...");

    const userId = localStorage.getItem('userId');
    if (!userId) {
      console.log("No userId found, cannot fetch orders.");
      return;
    }

    const fetchOrdersAndWaiterCalls = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          console.error("No token found in localStorage. Cannot fetch orders.");
          setError("No valid authentication token found. Please log in again.");
          navigate("/restaurant-login");
          return;
        }

        // Fetch Orders
        const response = await fetch(`${baseUrl}/api/orders?userId=${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 401) {
          console.error("Unauthorized: Token invalid or session expired.");
          setError("Session expired or unauthorized. Please log in again.");
          localStorage.removeItem("authToken");
          navigate("/restaurant-login");
          return;
        }

        if (!response.ok) {
          throw new Error("Failed to fetch orders");
        }

        const fetchedGroupedOrders = await response.json();
        setGroupedOrders(fetchedGroupedOrders);

        // Detect new orders and play sound based on new/repeat customer
        fetchedGroupedOrders.forEach(group => {
          const { whatsappNumber } = group;
          const isRepeatCustomer = previousCustomersRef.current.has(whatsappNumber);

          group.orders.forEach(order => {
            if (!previousOrderIdsRef.current.has(order._id) && order.status === "Pending") {
              if (isRepeatCustomer) {
                if (audioRefRepeat.current) {
                  audioRefRepeat.current.play().catch(err => {
                    console.error("Failed to play repeat customer audio:", err);
                  });
                }
              } else {
                if (audioRefNew.current) {
                  audioRefNew.current.play().catch(err => {
                    console.error("Failed to play new customer audio:", err);
                  });
                }
              }
            }
          });

          previousCustomersRef.current.add(whatsappNumber);
          group.orders.forEach(order => {
            previousOrderIdsRef.current.add(order._id);
          });
        });

        // Fetch Waiter Calls
        const waiterResponse = await fetch(`${baseUrl}/api/waiter-calls?userId=${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!waiterResponse.ok) {
          throw new Error("Failed to fetch waiter calls");
        }

        const fetchedWaiterCalls = await waiterResponse.json();
        console.log("Fetched waiter calls:", fetchedWaiterCalls);
        setWaiterCalls(fetchedWaiterCalls);

        // Check if there are any pending waiter calls
        const hasPendingCalls = fetchedWaiterCalls.some(call => call.status === 'Pending');
        if (hasPendingCalls) {
          audioRefWaiter.current.play().catch(err => {
            console.error("Failed to play waiter audio:", err);
          });
        } else {
          audioRefWaiter.current.pause();
          audioRefWaiter.current.currentTime = 0; // Reset to start
        }

        fetchedWaiterCalls.forEach(call => {
          previousWaiterCallIdsRef.current.add(call._id);
        });

      } catch (error) {
        console.error("Error fetching grouped orders or waiter calls:", error);
      }
    };

    fetchOrdersAndWaiterCalls();
    const intervalId = setInterval(fetchOrdersAndWaiterCalls, 10000);

    return () => clearInterval(intervalId);
  }, [navigate]); // Removed waiterCallAcceptanceState from dependencies

  // Construct the promotional links using the userId
  const userId = localStorage.getItem('userId');
  const frontendBaseUrl = "https://mywaiter.in"; 
  const promoLink = `${frontendBaseUrl}/menu/${userId}`;

  // Function to generate a table-specific QR code link
  const generateTableQR = (tableNumber) => {
    return `${frontendBaseUrl}/restaurant/${userId}/table/${tableNumber}`;
  };

  // Razorpay Payment Function for Unfreezing Account
  const initiatePayment = async () => {
    const userId = localStorage.getItem('userId');
    try {
      const orderResponse = await fetch(`${baseUrl}/api/users/payments/create-order`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: 500, userId }),
      });
      const orderData = await orderResponse.json();

      const options = {
        key: 'rzp_test_LJUA3y2Jt0M0GN',
        amount: orderData.amount,
        currency: 'INR',
        name: 'My Restaurant',
        description: 'Account Unfreeze Payment',
        order_id: orderData.id,
        handler: async function (response) {
          const confirmResponse = await fetch(`${baseUrl}/api/users/payments/confirm-payment`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              paymentId: response.razorpay_payment_id,
              orderId: response.razorpay_order_id,
              userId,
            }),
          });

          const result = await confirmResponse.json();

          if (result.success) {
            alert('Payment successful! Unfreezing account...');
            setIsFrozen(false);
          } else {
            alert('Payment verification failed. Please try again.');
          }
        },
        prefill: {
          name: 'Owner Name',
          email: 'owner@example.com',
          contact: '9999999999',
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error('Error during payment initiation:', error);
      alert('Failed to initiate payment. Please try again later.');
    }
  };

  // MENU ACTIVE/INACTIVE & SPECIAL TOGGLE HANDLERS
  const allItemsActive = menuItems.length > 0 && menuItems.every(item => item.active);

  const handleToggleItem = async (itemId) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('You are not logged in.');
      navigate('/restaurant-login');
      return;
    }

    try {
      const toggledItem = menuItems.find(item => item._id === itemId);
      const response = await fetch(`${baseUrl}/api/menu/toggle/${itemId}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ active: !toggledItem.active }),
      });

      if (response.ok) {
        const updatedMenuItems = menuItems.map(item =>
          item._id === itemId ? { ...item, active: !item.active } : item
        );
        setMenuItems(updatedMenuItems);
      } else {
        console.error('Failed to update item status:', response.statusText);
        setError('Failed to update item status');
      }
    } catch (err) {
      console.error('Failed to update item status:', err);
      setError('Failed to update item status');
    }
  };

  const handleToggleAll = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('You are not logged in.');
      navigate('/restaurant-login');
      return;
    }

    try {
      const newStatus = !allItemsActive;
      const response = await fetch(`${baseUrl}/api/menu/toggle-all`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ active: newStatus }),
      });

      if (response.ok) {
        const updatedMenuItems = menuItems.map(item => ({ ...item, active: newStatus }));
        setMenuItems(updatedMenuItems);
      } else {
        console.error('Failed to update items status:', response.statusText);
        setError('Failed to update items status');
      }
    } catch (err) {
      console.error('Failed to update items status:', err);
      setError('Failed to update items status');
    }
  };

  const handleToggleSpecial = async (itemId) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('You are not logged in.');
      navigate('/restaurant-login');
      return;
    }

    try {
      const toggledItem = menuItems.find(item => item._id === itemId);
      const response = await fetch(`${baseUrl}/api/menu/special/${itemId}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ special: !toggledItem.special }),
      });

      if (response.ok) {
        const updatedMenuItems = menuItems.map(item =>
          item._id === itemId ? { ...item, special: !item.special } : item
        );
        setMenuItems(updatedMenuItems);
      } else {
        console.error('Failed to update special status:', response.statusText);
        setError('Failed to update special status');
      }
    } catch (err) {
      console.error('Failed to update special status:', err);
      setError('Failed to update special status');
    }
  };

  // ORDER STATUS HANDLERS
  const handleOrderStatus = async (orderId, status) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('You are not logged in.');
      navigate('/restaurant-login');
      return;
    }
  
    try {
      const response = await fetch(`${baseUrl}/api/orders/status/${orderId}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to update order status to ${status}`);
      }
  
      setGroupedOrders((prevGroupedOrders) =>
        prevGroupedOrders.map((group) => ({
          ...group,
          orders: group.orders.map((order) =>
            order._id === orderId ? { ...order, status } : order
          ),
        }))
      );
  
      const statusMessage =
        status === 'Accepted'
          ? 'Order accepted successfully!'
          : status === 'Rejected'
          ? 'Order rejected successfully!'
          : 'Order status updated successfully!';
      alert(statusMessage);
    } catch (err) {
      console.error(`Error updating order status to ${status}:`, err);
      setError(`Failed to update order status to ${status}.`);
    }
  };
  
  const handleAcceptOrder = async (orderId) => {
    const confirmAccept = window.confirm('Are you sure you want to accept this order?');
    if (!confirmAccept) return;
    handleOrderStatus(orderId, 'Accepted');
  };
  
  const handleRejectOrder = async (orderId) => {
    const confirmReject = window.confirm('Are you sure you want to reject this order?');
    if (!confirmReject) return;
    handleOrderStatus(orderId, 'Rejected');
  };

  // WAITER CALL HANDLER
  const handleAcceptWaiterCall = async (callId) => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      setError('You are not logged in.');
      navigate('/restaurant-login');
      return;
    }

    try {
      console.log(`Attempting to accept and remove waiter call ${callId}`);
      const response = await fetch(`${baseUrl}/api/waiter-calls/accept/${callId}`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Failed to accept waiter call: ${errorData.error || 'Unknown error'}`);
      }

      const responseData = await response.json();
      console.log(`Waiter call ${callId} accepted and removed:`, responseData);

      // Remove the waiter call from the state immediately
      setWaiterCalls(prevCalls => {
        const updatedCalls = prevCalls.filter(call => call._id !== callId);
        console.log("Updated waiter calls after removal:", updatedCalls);
        return updatedCalls;
      });

      // Stop the waiter audio if no pending calls remain
      const hasPendingCalls = waiterCalls.some(call => 
        call.status === 'Pending' && call._id !== callId
      );
      if (!hasPendingCalls) {
        console.log("No pending calls remain, stopping audio");
        audioRefWaiter.current.pause();
        audioRefWaiter.current.currentTime = 0;
      }

      alert('Waiter call accepted and removed successfully!');
    } catch (err) {
      console.error('Error accepting waiter call:', err.message);
      setError('Failed to accept waiter call.');
    }
  };

  // LINK/SHARE HANDLERS
  const handleCopyLink = () => {
    if (promoLink) {
      navigator.clipboard.writeText(promoLink)
        .then(() => {
          alert('Promotional link copied to clipboard!');
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
          alert('Failed to copy the link. Please try manually.');
        });
    }
  };

  const handleShare = async () => {
    if (!navigator.share) {
      alert('Web Share API is not supported in your browser.');
      return;
    }

    try {
      await navigator.share({
        title: 'Check out my restaurant menu!',
        text: 'Here is the promotional link to my restaurant:',
        url: promoLink,
      });
      console.log('Promotional link shared successfully');
    } catch (err) {
      console.error('Error sharing:', err);
    }
  };

  // DOWNLOAD HANDLER FOR TABLE QR CODES
  const handleDownloadTableQR = (tableNumber, tableLink) => {
    const canvas = document.getElementById(`table-qr-${tableNumber}`);
    if (canvas) {
      const pngUrl = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `table_${tableNumber}_qr.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  // LOGOUT HANDLER
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userId");
    navigate("/restaurant-login");
  };

  // EXPORT ORDERS HANDLER
  const handleExportOrders = async () => {
    const months = window.prompt("Enter the number of months for which you want to export orders:", "1");
    if (!months) return;
    const monthsInt = parseInt(months);
    if (isNaN(monthsInt) || monthsInt <= 0) {
      alert("Please enter a valid positive number for months.");
      return;
    }
    try {
      const response = await fetch(`${baseUrl}/api/orders/export?userId=${userId}&months=${monthsInt}`);
      if (!response.ok) {
        throw new Error("Failed to export orders");
      }
      const blob = await response.blob();
      const contentDisposition = response.headers.get('Content-Disposition');
      let filename = "orders.xlsx";
      if (contentDisposition) {
        const match = contentDisposition.match(/filename=([^;]+)/);
        if (match && match[1]) {
          filename = match[1].replace(/"/g, '');
        }
      }
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error exporting orders:", error);
      alert("Failed to export orders. Please try again later.");
    }
  };

  // MAIN RENDER
  return (
    <div className="min-h-screen bg-gray-50 p-6">
      {isFrozen ? (
        <div className="flex items-center justify-center bg-gray-100" style={{ minHeight: '80vh' }}>
          <div className="text-center p-6 bg-white shadow rounded-lg">
            <h2 className="text-2xl font-bold text-red-500 mb-4">Dear Owner</h2>
            <p className="text-gray-700 mb-6">
              Your Account is in Freeze Mode, and <b>NO Functions</b> will work because your payment for this month is pending.
            </p>
            <p className="text-gray-700 mb-6">
              Please pay <b>INR 500</b> using the 'Pay' button OR contact us by clicking the WhatsApp link below.
            </p>
            <div className="mt-6 flex flex-col space-y-4 items-center">
              <button
                onClick={() =>
                  window.open(
                    `https://wa.me/918472958581?text=Hello,%20My%20Restaurant%20Name%20is:%20[Your Restaurant Name].%20Owner:%20[Your Name].`,
                    '_blank'
                  )
                }
                className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              >
                Contact via WhatsApp
              </button>
              <button
                onClick={initiatePayment}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Pay Now
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          {error && (
            <Alert variant="destructive">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          {freezeErrorMessage && (
            <Alert variant="destructive">
              <AlertDescription>{freezeErrorMessage}</AlertDescription>
            </Alert>
          )}

          <div className="max-w-6xl mx-auto space-y-6">
            {/* Waiter Calls Section */}
            <div>
              <h2 className="text-2xl font-bold mb-4">Waiter Calls</h2>
              {waiterCalls.map((call) => (
                <div key={call._id} className="waiter-call-card border rounded-lg p-4 bg-white mb-4">
                  <p className="text-sm text-gray-600">Table Number: {call.tableNumber}</p>
                  <p className="text-sm text-gray-600">
                    Requested At: {new Date(call.requestedAt).toLocaleString()}
                  </p>
                  <button
                    onClick={() => handleAcceptWaiterCall(call._id)}
                    className="mt-2 px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                  >
                    Accept Call
                  </button>
                </div>
              ))}
              {waiterCalls.length === 0 && (
                <p className="text-center text-gray-500">No waiter calls at the moment</p>
              )}
            </div>

            {/* Orders Section */}
            <div>
              <h2 className="text-2xl font-bold mb-4">Orders</h2>
              {groupedOrders.map((group) => (
                <div key={group.whatsappNumber} className="order-card border rounded-lg p-4 bg-white mb-4">
                  <p className="font-medium">Customer Name: {group.customerName}</p>
                  <p className="text-sm text-gray-600">WhatsApp Number: {group.whatsappNumber}</p>
                  <p className="font-medium mt-2">Orders:</p>
                  {group.orders.map((order) => (
                    <div key={order._id} className="mt-2">
                      <p className="text-sm text-gray-600">Order ID: {order._id}</p>
                      {order.tableNumber && (
                        <p className="text-sm text-gray-600">Table Number: {order.tableNumber}</p>
                      )}
                      <p className="text-sm text-gray-600">Status: {order.status}</p>
                      <p className="text-sm text-gray-600">Total Amount: ₹{order.totalAmount}</p>
                      <p className="text-sm text-gray-600">
                        Placed At: {new Date(order.placedAt).toLocaleString()}
                      </p>
                      <p className="font-medium mt-2">Items:</p>
                      <ul className="list-disc list-inside">
                        {order.items.map((item, index) => (
                          <li key={index} className="text-sm">
                            {item.name} - Quantity: {item.quantity}
                          </li>
                        ))}
                      </ul>
                      <div className="flex space-x-2 mt-4">
                        {order.status === 'Pending' ? (
                          <>
                            <button
                              onClick={() => handleAcceptOrder(order._id)}
                              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                            >
                              Accept
                            </button>
                            <button
                              onClick={() => handleRejectOrder(order._id)}
                              className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                            >
                              Reject
                            </button>
                          </>
                        ) : (
                          <p
                            className={`text-sm font-bold ${
                              order.status === 'Accepted' ? 'text-green-500' : 'text-red-500'
                            }`}
                          >
                            {order.status === 'Accepted' ? 'Order Accepted' : 'Order Rejected'}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
              {groupedOrders.length === 0 && (
                <p className="text-center text-gray-500">No orders available</p>
              )}
            </div>

            {/* Menu Management Section */}
            <Card>
              <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-xl font-bold">Menu Items</CardTitle>
                <button
                  onClick={() => navigate('/menu-setup')}
                  className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-blue-600 flex items-center space-x-2"
                >
                  <Pencil className="w-4 h-4" />
                  <span>Edit Menu</span>
                </button>
              </CardHeader>
              <CardContent>
                <div className="border-b pb-4 mb-4 flex items-center justify-between">
                  <div>
                    <span className="font-medium">All Items</span>
                    <p className="text-sm text-gray-500">
                      {allItemsActive ? 'All items available' : 'No items available'}
                    </p>
                  </div>
                  <Switch
                    checked={allItemsActive}
                    onCheckedChange={handleToggleAll}
                  />
                </div>
                <div className="space-y-4">
                  {menuItems.map((item) => (
                    <div key={item._id} className="flex items-center justify-between border-b py-3">
                      <span className="font-medium">{item.name}</span>
                      <div className="flex items-center space-x-2">
                        <span className="text-sm text-gray-500">
                          {item.active ? 'Available' : 'Not Available'}
                        </span>
                        <Switch
                          checked={item.active}
                          onCheckedChange={() => handleToggleItem(item._id)}
                        />
                        <button onClick={() => handleToggleSpecial(item._id)}>
                          <Star
                            className={`w-6 h-6 ${
                              item.special ? 'text-yellow-500 fill-current' : 'text-gray-500'
                            }`}
                            fill={item.special ? 'currentColor' : 'none'} 
                          />
                        </button>
                      </div>
                    </div>
                  ))}
                  {menuItems.length === 0 && (
                    <p className="text-center text-gray-500">No menu items available</p>
                  )}
                </div>
              </CardContent>
            </Card>

            {/* Share Your Webapp Section */}
            <Card>
              <CardHeader>
                <CardTitle className="text-xl font-bold">Share my Webapp</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="flex space-x-2 mt-2 md:mt-0">
                      <button
                        onClick={handleCopyLink}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center space-x-1"
                      >
                        <Copy className="w-4 h-4" />
                        <span>Copy Webapp</span>
                      </button>
                      <button
                        onClick={handleShare}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 flex items-center space-x-1"
                      >
                        <Share2 className="w-4 h-4" />
                        <span>Share Webapp</span>
                      </button>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>

            {/* Table QR Codes Section */}
            <Card>
              <CardHeader className="flex flex-row items-center justify-between">
                <CardTitle className="text-xl font-bold">Table QR Codes</CardTitle>
                <button
                  onClick={() => setIsTableQRExpanded(prev => !prev)}
                  className="p-2"
                  aria-label="Toggle QR Codes"
                >
                  {isTableQRExpanded ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
                </button>
              </CardHeader>
              {isTableQRExpanded && (
                <CardContent>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                    {Array.from({ length: totalTables }, (_, index) => {
                      const tableNumber = index + 1;
                      const tableLink = generateTableQR(tableNumber);
                      return (
                        <div key={tableNumber} className="flex flex-col items-center border p-4 rounded bg-white">
                          <p className="font-medium mb-2">Table {tableNumber}</p>
                          <QRCodeCanvas id={`table-qr-${tableNumber}`} value={tableLink} size={128} includeMargin={true} />
                          <button
                            onClick={() => handleDownloadTableQR(tableNumber, tableLink)}
                            className="mt-2 px-3 py-1 bg-purple-500 text-white rounded-md hover:bg-purple-600"
                          >
                            Download QR
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </CardContent>
              )}
            </Card>

            {/* Export Orders Section */}
            <Card>
              <CardHeader>
                <CardTitle className="text-xl font-bold">Export Order Data</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="mb-2 text-gray-600">
                  Export your orders from the past number of months as an Excel file.
                </p>
                <button
                  onClick={handleExportOrders}
                  className="px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600"
                >
                  Export Orders
                </button>
              </CardContent>
            </Card>

            {/* Logout Button */}
            <div className="text-center mt-6">
              <button
                onClick={handleLogout}
                className="px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-800"
              >
                Logout
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RestaurantDashboard;