import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useSpring, animated } from 'react-spring';
import {
  ShoppingBag,
  Star,
  Clock,
  Leaf,
  Beef,
  Play,
  Volume2,
  VolumeX,
  ChevronDown,
  ChevronUp,
  X,
} from 'lucide-react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Switch } from './ui/switch';
import loadingAnimation from '../assets/Loading.json';
import waiterAnimation from '../assets/waiter.json';
import Lottie from 'react-lottie-player';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from './ui/card';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import MyCart from './MyCart';
import OrderDetailsForm from './OrderDetailsForm';

// Animation Variants
const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5, ease: 'easeOut' } },
  exit: { opacity: 0, y: -50, transition: { duration: 0.3 } },
};

const buttonVariants = {
  hover: { scale: 1.05, transition: { duration: 0.2 } },
  tap: { scale: 0.95 },
};

const VideoCard = React.memo(({
  videoUrl,
  thumbnailUrl,
  addToCart,
  removeFromCart,
  updateCartItem,
  isInCart,
  cartItems,
  setViewCart,
  item,
  sortedMenuItems,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    controls.start('visible');
  }, [controls]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsPlaying(entry.isIntersecting),
      { threshold: 0.5 }
    );
    if (videoRef.current) observer.observe(videoRef.current);
    return () => {
      if (videoRef.current) observer.unobserve(videoRef.current);
    };
  }, []);

  const cartItem = cartItems.find((cartItem) => cartItem.id === item.id);
  const quantity = cartItem ? cartItem.quantity : 1;

  const handleAddToCart = (e) => {
    e.stopPropagation();
    e.preventDefault();
    addToCart(item, 1);
  };
  const handleRemoveFromCart = (e) => {
    e.stopPropagation();
    e.preventDefault();
    removeFromCart(item);
  };
  const handleDecreaseQuantity = (e) => {
    e.stopPropagation();
    e.preventDefault();
    quantity > 1 && updateCartItem(item.id, quantity - 1);
  };
  const handleIncreaseQuantity = (e) => {
    e.stopPropagation();
    e.preventDefault();
    updateCartItem(item.id, quantity + 1);
  };
  const handleMuteToggle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsMuted(!isMuted);
  };
  const handleViewCart = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setViewCart(true);
  };
  const handlePlayClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsPlaying(true);
  };

  const getVideoId = (url) => {
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/embed\/([^?&]+)/);
    return match ? match[1] : '';
  };

  const index = sortedMenuItems.findIndex((menuItem) => menuItem.id === item.id);
  const discount = index < 10 ? 0.4 : index < 20 ? 0.2 : 0.1;
  const originalPrice = item.price;
  const inflatedPrice = Math.round(originalPrice / (1 - discount));

  return (
    <motion.div
      ref={videoRef}
      className="relative w-full h-full"
      animate={controls}
      variants={cardVariants}
    >
      <div className="relative w-full" style={{ paddingTop: '100%' }}>
        <div className="absolute top-0 left-0 w-full h-full bg-black rounded-t-lg overflow-hidden shadow-lg">
          {!isPlaying ? (
            <motion.div
              className="w-full h-full bg-cover bg-center cursor-pointer relative"
              style={{ backgroundImage: `url(${thumbnailUrl})` }}
              onClick={handlePlayClick}
              whileHover={{ scale: 1.02 }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                <Play className="w-16 h-16 text-white" />
              </div>
            </motion.div>
          ) : (
            <div className="relative w-full h-full overflow-hidden pointer-events-none">
              <iframe
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[177.78%] h-[177.78%]"
                src={`${videoUrl}?autoplay=1&mute=${isMuted ? 1 : 0}&loop=1&playlist=${getVideoId(videoUrl)}&modestbranding=1&rel=0&iv_load_policy=3&enablejsapi=1`}
                allow="autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="item-video"
              />
            </div>
          )}

          <motion.div
            className="absolute bottom-0 left-0 right-0 p-2 bg-gradient-to-t from-black/70 to-transparent z-10"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            {isInCart && (
              <div className="flex justify-end mb-2">
                <div className="flex items-center space-x-2">
                  <motion.button
                    onClick={handleDecreaseQuantity}
                    className="px-2 py-1 bg-gray-200 rounded"
                    whileHover={buttonVariants.hover}
                    whileTap={buttonVariants.tap}
                  >
                    -
                  </motion.button>
                  <span className="px-4 py-1 bg-white rounded border">{quantity}</span>
                  <motion.button
                    onClick={handleIncreaseQuantity}
                    className="px-2 py-1 bg-gray-200 rounded"
                    whileHover={buttonVariants.hover}
                    whileTap={buttonVariants.tap}
                  >
                    +
                  </motion.button>
                </div>
              </div>
            )}
            <div className="flex items-center justify-between">
              <motion.button
                onClick={handleMuteToggle}
                className="p-2 text-white rounded-full hover:bg-black/20"
                whileHover={{ scale: 1.1 }}
              >
                {isMuted ? <VolumeX className="w-5 h-5" /> : <Volume2 className="w-5 h-5" />}
              </motion.button>
              <div className="flex items-center space-x-2">
                {isInCart && (
                  <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} className="text-green-500 font-bold">
                    ✅ Added
                  </motion.span>
                )}
                {isInCart ? (
                  <motion.button
                    onClick={handleRemoveFromCart}
                    className="px-4 py-2 text-white rounded-lg bg-gradient-to-r from-pink-500 to-red-500"
                    whileHover={buttonVariants.hover}
                    whileTap={buttonVariants.tap}
                  >
                    Remove
                  </motion.button>
                ) : (
                  <motion.button
                    onClick={handleAddToCart}
                    className="px-4 py-2 text-white rounded-lg bg-gradient-to-r from-red-500 to-orange-500"
                    whileHover={{ backgroundImage: 'linear-gradient(to right, #f97316, #9333ea)' }}
                    whileTap={buttonVariants.tap}
                  >
                    Add to Cart
                  </motion.button>
                )}
                <motion.div
                  className="relative bg-black p-1.5 rounded-full"
                  whileHover={{ scale: 1.1 }}
                  onClick={handleViewCart}
                >
                  <ShoppingBag className="h-6 w-6 text-white cursor-pointer" />
                  {cartItems.length > 0 && (
                    <motion.span
                      className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                    >
                      {cartItems.reduce((acc, item) => acc + item.quantity, 0)}
                    </motion.span>
                  )}
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>
        {/* Transparent overlay for swipe detection */}
        <div 
          className="absolute inset-0 z-5"
          style={{ touchAction: 'pan-y' }}
        />
      </div>
    </motion.div>
  );
});

const ListViewCard = React.memo(({
  item,
  addToCart,
  removeFromCart,
  updateCartItem,
  isInCart,
  cartItems,
  setViewCart,
  handleToggleSpecial,
  sortedMenuItems,
}) => {
  const cartItem = cartItems.find((cartItem) => cartItem.id === item.id);
  const quantity = cartItem ? cartItem.quantity : 1;
  const controls = useAnimation();

  useEffect(() => {
    controls.start('visible');
  }, [controls]);

  const index = sortedMenuItems.findIndex((menuItem) => menuItem.id === item.id);
  const discount = index < 10 ? 0.4 : index < 20 ? 0.2 : 0.1;
  const originalPrice = item.price;
  const inflatedPrice = Math.round(originalPrice / (1 - discount));

  return (
    <motion.div
      className={`bg-white border rounded-lg shadow-md p-4 flex flex-col space-y-2 ${
        item.special ? 'border-red-500' : 'border-gray-200'
      }`}
      variants={cardVariants}
      animate={controls}
      whileHover={{ scale: 1.02, boxShadow: '0 10px 20px rgba(0,0,0,0.1)' }}
    >
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-lg font-bold flex items-center space-x-2">
            <span>{item.name}</span>
            {item.dietType === 'Vegetarian' ? (
              <Leaf className="w-4 h-4 text-green-500" />
            ) : (
              <Beef className="w-4 h-4 text-red-500" />
            )}
          </h2>
          {item.special && (
            <motion.div
              className="flex items-center space-x-1 mt-1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <Star className="w-5 h-5 text-red-500" fill="currentColor" />
              <span className="text-sm text-red-600 font-bold">Today's Special</span>
            </motion.div>
          )}
        </div>
        <div className="text-right">
          <p className="text-sm">
            <span className="text-gray-500 line-through">₹{inflatedPrice}</span>
            <span className="text-purple-600 font-bold ml-1"> ({discount * 100}% off)</span>
          </p>
          <p className="text-xl font-bold text-blue-600">₹{originalPrice}</p>
        </div>
      </div>

      <p className="text-gray-500">{item.cuisine} Cuisine</p>

      <div className="flex items-center space-x-2">
        <Star className="h-5 w-5 text-yellow-400" />
        <span>{item.rating}</span>
        <Clock className="h-5 w-5 text-gray-400 ml-2" />
        <span>{item.prepTime}</span>
      </div>

      <div className="flex justify-end items-center space-x-4 relative mt-2">
        {isInCart && (
          <motion.span initial={{ scale: 0 }} animate={{ scale: 1 }} className="text-green-500">
            ✅ Added
          </motion.span>
        )}
        {isInCart ? (
          <motion.button
            onClick={() => removeFromCart(item)}
            className="px-4 py-2 text-white rounded-lg bg-gradient-to-r from-pink-500 to-red-500"
            whileHover={buttonVariants.hover}
            whileTap={buttonVariants.tap}
          >
            Remove
          </motion.button>
        ) : (
          <motion.button
            onClick={() => addToCart(item, 1)}
            className="px-4 py-2 text-white rounded-lg bg-gradient-to-r from-red-500 to-orange-500"
            whileHover={{ backgroundImage: 'linear-gradient(to right, #f97316, #9333ea)' }}
            whileTap={buttonVariants.tap}
          >
            Add to Cart
          </motion.button>
        )}
        <motion.div
          className="relative cursor-pointer"
          whileHover={{ scale: 1.1 }}
          onClick={() => setViewCart(true)}
        >
          <ShoppingBag className="h-6 w-6 text-gray-700" />
          {cartItems.length > 0 && (
            <motion.span
              className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
            >
              {cartItems.reduce((acc, item) => acc + item.quantity, 0)}
            </motion.span>
          )}
        </motion.div>
      </div>

      {isInCart && (
        <motion.div
          className="flex justify-end items-center space-x-2 mt-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <motion.button
            onClick={() => updateCartItem(item.id, quantity - 1)}
            className="px-2 py-1 bg-gray-200 rounded"
            disabled={quantity <= 1}
            whileHover={buttonVariants.hover}
            whileTap={buttonVariants.tap}
          >
            -
          </motion.button>
          <span className="px-4 py-1 bg-white rounded border">{quantity}</span>
          <motion.button
            onClick={() => updateCartItem(item.id, quantity + 1)}
            className="px-2 py-1 bg-gray-200 rounded"
            whileHover={buttonVariants.hover}
            whileTap={buttonVariants.tap}
          >
            +
          </motion.button>
        </motion.div>
      )}
    </motion.div>
  );
});

const SuggestedItemCard = React.memo(({ item, addToCart }) => {
  const controls = useAnimation();

  useEffect(() => {
    controls.start('visible');
  }, [controls]);

  const originalPrice = item.price;
  const inflatedPrice = Math.round(originalPrice * 1.1);

  return (
    <motion.div
      className="bg-white border rounded-lg shadow-md p-3 flex flex-col space-y-2"
      variants={cardVariants}
      animate={controls}
      whileHover={{ scale: 1.03 }}
    >
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-md font-bold flex items-center space-x-1">
            <span>{item.name}</span>
            {item.dietType === 'Vegetarian' ? (
              <Leaf className="w-3 h-3 text-green-500" />
            ) : (
              <Beef className="w-3 h-3 text-red-500" />
            )}
          </h3>
          <p className="text-sm text-gray-500">{item.cuisine}</p>
        </div>
        <div className="text-right">
          <p className="text-xs text-gray-500 line-through">₹{inflatedPrice}</p>
          <p className="text-md font-bold text-blue-600">₹{originalPrice}</p>
        </div>
      </div>
      <motion.button
        onClick={() => addToCart(item, 1)}
        className="w-full py-1 text-sm text-white rounded-lg bg-gradient-to-r from-red-500 to-orange-500"
        whileHover={{ backgroundImage: 'linear-gradient(to right, #f97316, #9333ea)' }}
        whileTap={buttonVariants.tap}
      >
        Add to Cart
      </motion.button>
    </motion.div>
  );
});

const RestaurantApp = () => {
  const { id, tableNumber: paramTableNumber } = useParams();
  const [tableNumber, setTableNumber] = useState(paramTableNumber || localStorage.getItem('tableNumber') || null);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedDietType, setSelectedDietType] = useState('All');
  const [selectedCuisine, setSelectedCuisine] = useState('All');
  const [cartItems, setCartItems] = useState([]);
  const [viewCart, setViewCart] = useState(false);
  const [viewOrderDetails, setViewOrderDetails] = useState(false);
  const [restaurantData, setRestaurantData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isListView, setIsListView] = useState(true);
  const [categories, setCategories] = useState(['All']);
  const [cuisines, setCuisines] = useState(['All']);
  const [showFilters, setShowFilters] = useState(false);
  const [showWaiterPopup, setShowWaiterPopup] = useState(false);
  const [timer, setTimer] = useState(120);

  const backgroundProps = useSpring({
    from: { backgroundPosition: '0% 0%' },
    to: { backgroundPosition: '100% 100%' },
    config: { duration: 10000 },
    loop: true,
  });

  useEffect(() => {
    if (paramTableNumber) {
      setTableNumber(paramTableNumber);
      localStorage.setItem('tableNumber', paramTableNumber);
    }
  }, [paramTableNumber]);

  useEffect(() => {
    const fetchRestaurantData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const BASE_URL = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(
          `${BASE_URL}/api/public/restaurant-data?id=${id}&tableNumber=${tableNumber}`
        );
        if (!response.ok) throw new Error('Failed to fetch restaurant data');
        const data = await response.json();
        if (data && Object.keys(data).length > 0) {
          setRestaurantData(data);
          setCategories(['All', ...new Set(data.menu.map((item) => item.category))]);
          setCuisines(['All', ...new Set(data.menu.map((item) => item.cuisine))]);
          if (tableNumber) localStorage.setItem('tableNumber', tableNumber);
        } else {
          setIsError(true);
        }
      } catch (error) {
        console.error('Error fetching restaurant data:', error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    if (id) fetchRestaurantData();
  }, [id, tableNumber]);

  const sortedMenuItems = useMemo(() => {
    if (!restaurantData) return [];
    return [...restaurantData.menu].sort((a, b) => b.price - a.price);
  }, [restaurantData]);

  const addToCart = (item, quantity = 1) => {
    setCartItems((prevCartItems) => {
      const existingItem = prevCartItems.find((cartItem) => cartItem.id === item.id);
      return existingItem
        ? prevCartItems.map((cartItem) =>
            cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity + quantity } : cartItem
          )
        : [...prevCartItems, { ...item, quantity }];
    });
  };

  const removeFromCart = (item) => {
    setCartItems((prevCartItems) => prevCartItems.filter((cartItem) => cartItem.id !== item.id));
  };

  const updateCartItem = (itemId, newQuantity) => {
    setCartItems((prevCartItems) =>
      prevCartItems
        .map((item) => (item.id === itemId ? { ...item, quantity: newQuantity } : item))
        .filter((item) => item.quantity > 0)
    );
  };

  const handleToggleSpecial = (itemId) => {
    setRestaurantData((prevData) => {
      if (!prevData) return null;
      const updatedMenu = prevData.menu.map((menuItem) =>
        menuItem.id === itemId ? { ...menuItem, special: !menuItem.special } : menuItem
      );
      return { ...prevData, menu: updatedMenu };
    });
  };

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const placeOrder = async (customerName, whatsappNumber, tableNumber) => {
    if (cartItems.length === 0) {
      alert('Your cart is empty!');
      return;
    }
    const orderPayload = {
      userId: id,
      tableNumber: tableNumber || null,
      items: cartItems.map((item) => ({
        name: item.name,
        quantity: item.quantity,
        price: item.price,
      })),
      totalAmount: cartItems.reduce((total, item) => total + item.price * item.quantity, 0),
      customerName,
      whatsappNumber,
    };
    try {
      const response = await fetch(`${BASE_URL}/api/orders`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(orderPayload),
      });
      if (response.ok) return await response.json();
      console.error('Failed to place order.');
      return { success: false, message: 'Failed to place order.' };
    } catch (error) {
      console.error('Error placing order:', error);
      throw new Error('Something went wrong while placing the order.');
    }
  };

  const filteredMenuItems = useMemo(() => {
    if (!restaurantData) return [];
    return restaurantData.menu
      .filter((item) => {
        const matchesCategory = selectedCategory === 'All' || item.category === selectedCategory;
        const matchesCuisine = selectedCuisine === 'All' || item.cuisine === selectedCuisine;
        const matchesDietType = selectedDietType === 'All' || item.dietType === 'Vegetarian';
        return matchesCategory && matchesCuisine && matchesDietType;
      })
      .sort((a, b) => b.special - a.special);
  }, [restaurantData, selectedCategory, selectedCuisine, selectedDietType]);

  const totalItemCount = cartItems.reduce((acc, item) => acc + item.quantity, 0);

  const fetchOrderStatus = async (orderId) => {
    try {
      const response = await fetch(`${BASE_URL}/api/orders/status/${orderId}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      if (response.ok) return (await response.json()).status;
      console.error('Failed to fetch order status.');
      return null;
    } catch (error) {
      console.error('Error fetching order status:', error);
      return null;
    }
  };

  const dietTypes = [
    { value: 'All', label: 'All' },
    { value: 'Vegetarian', label: 'Veg' },
    { value: 'Non-Vegetarian', label: 'Non-Veg' },
  ];

  const handleCallWaiter = async () => {
    if (!id || !tableNumber) {
      alert('Unable to call waiter: Missing restaurant ID or table number.');
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/api/waiter-calls`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: id,
          tableNumber,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to call waiter');
      }

      const result = await response.json();
      console.log('Waiter call response:', result);
      setShowWaiterPopup(true);
      setTimer(120);
    } catch (error) {
      console.error('Error calling waiter:', error);
      alert('Failed to notify waiter. Please try again.');
    }
  };

  useEffect(() => {
    if (showWaiterPopup && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      setShowWaiterPopup(false);
    }
  }, [showWaiterPopup, timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const renderMainContent = () => (
    <>
      <motion.header
        className="bg-white shadow-sm"
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="max-w-7xl mx-auto px-4 py-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <motion.img
                src={restaurantData.logo}
                alt={`${restaurantData.name} Logo`}
                className="h-10"
                whileHover={{ scale: 1.1 }}
              />
              <h1 className="text-2xl font-bold text-gray-800" style={{ fontFamily: 'Georgia, serif' }}>
                {restaurantData.name}
              </h1>
            </div>
            <motion.button
              onClick={handleCallWaiter}
              className="px-4 py-2 text-white rounded-lg bg-gradient-to-r from-red-500 to-red-700 hover:bg-blue-700 flex items-center space-x-2"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <span>Call Waiter</span>
              <span>👋</span>
            </motion.button>
          </div>
        </div>
      </motion.header>

      <div className="max-w-7xl mx-auto px-4 py-6">
        <motion.div
          className="flex items-center justify-between"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          <motion.div
            className="flex items-center space-x-2 px-3 py-2 rounded-lg shadow-sm bg-white/80 backdrop-blur-sm"
            style={{ minWidth: '120px' }}
            whileHover={{ scale: 1.05 }}
          >
            <span
              className={`text-sm font-medium ${
                selectedDietType === 'All' ? 'text-black' : 'text-black-400'
              }`}
            >
              🟢🔴
            </span>
            <Switch
              checked={selectedDietType === 'Vegetarian'}
              onCheckedChange={(checked) => setSelectedDietType(checked ? 'Vegetarian' : 'All')}
            />
            <span
              className={`text-sm font-medium ${
                selectedDietType === 'Vegetarian' ? 'text-black' : 'text-gray-400'
              }`}
            >
              🟢
            </span>
          </motion.div>

          <motion.div
            className="flex items-center space-x-2 px-3 py-2 rounded-lg shadow-sm bg-white/80 backdrop-blur-sm"
            style={{ minWidth: '100px' }}
            whileHover={{ scale: 1.05 }}
          >
            <span className="text-sm font-medium text-gray-700" style={{ display: 'inline-block', width: '20 intende-block', width: '20px', textAlign: 'center' }}>{isListView ? '☰' : '🎬'}</span>
            <Switch checked={isListView} onCheckedChange={(checked) => setIsListView(checked)} />
          </motion.div>
        </motion.div>
      </div>

      <div className="max-w-7xl mx-auto px-4 space-y-4 flex flex-col items-center">
        <motion.div
          className="bg-white p-4 w-full md:w-3/4 lg:w-2/3 mx-auto space-y-6 bg-opacity-80 backdrop-blur-sm rounded-lg"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <div className="w-full overflow-x-auto pb-2">
            <div className="inline-flex gap-4">
              {categories.map((category) => (
                <motion.button
                  key={category}
                  onClick={() => setSelectedCategory(category)}
                  className={`px-6 py-2 rounded-full text-sm font-medium whitespace-nowrap ${
                    selectedCategory === category
                      ? 'bg-black text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {category}
                </motion.button>
              ))}
            </div>
          </div>

          <div className="w-full overflow-x-auto pb-2">
            <div className="inline-flex gap-4">
              {cuisines.map((cuisine) => (
                <motion.button
                  key={cuisine}
                  onClick={() => setSelectedCuisine(cuisine)}
                  className={`px-6 py-2 rounded-full text-sm font-medium whitespace-nowrap ${
                    selectedCuisine === cuisine
                      ? 'bg-black text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  {cuisine}
                </motion.button>
              ))}
            </div>
          </div>
        </motion.div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-8">
        <AnimatePresence mode="wait">
          {isListView ? (
            <motion.div
              key="list"
              className="space-y-4"
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{ duration: 0.5 }}
            >
              {filteredMenuItems.map((item) => (
                <ListViewCard
                  key={item.id}
                  item={item}
                  addToCart={addToCart}
                  removeFromCart={removeFromCart}
                  updateCartItem={updateCartItem}
                  isInCart={cartItems.some((cartItem) => cartItem.id === item.id)}
                  cartItems={cartItems}
                  setViewCart={setViewCart}
                  handleToggleSpecial={handleToggleSpecial}
                  sortedMenuItems={sortedMenuItems}
                />
              ))}
            </motion.div>
          ) : (
            <motion.div
              key="swiper"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
            >
              <Swiper
                spaceBetween={16}
                slidesPerView={1}
                breakpoints={{ 640: { slidesPerView: 1.5 }, 1024: { slidesPerView: 2.5 } }}
              >
                {filteredMenuItems.map((item) => {
                  const index = sortedMenuItems.findIndex((menuItem) => menuItem.id === item.id);
                  const discount = index < 10 ? 0.4 : index < 20 ? 0.2 : 0.1;
                  const originalPrice = item.price;
                  const inflatedPrice = Math.round(originalPrice / (1 - discount));

                  return (
                    <SwiperSlide key={item.id}>
                      <Card className="overflow-hidden hover:shadow-lg transition-shadow h-full">
                        <VideoCard
                          videoUrl={item.videoUrl}
                          thumbnailUrl={item.thumbnailUrl}
                          addToCart={addToCart}
                          removeFromCart={removeFromCart}
                          updateCartItem={updateCartItem}
                          isInCart={cartItems.some((cartItem) => cartItem.id === item.id)}
                          cartItems={cartItems}
                          setViewCart={setViewCart}
                          item={item}
                          sortedMenuItems={sortedMenuItems}
                        />
                        <CardHeader>
                          <div className="flex justify-between items-start">
                            <div>
                              <div className="flex items-center space-x-2">
                                <CardTitle className="text-xl font-bold">{item.name}</CardTitle>
                                {item.dietType === 'Vegetarian' ? (
                                  <Leaf className="w-4 h-4 text-green-500" />
                                ) : (
                                  <Beef className="w-4 h-4 text-red-500" />
                                )}
                              </div>

                              {item.special && (
                                <motion.div
                                  className="flex items-center space-x-1 mt-2"
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                >
                                  <Star className="w-4 h-4 text-red-500" fill="currentColor" />
                                  <span className="text-sm text-red-500 font-bold">Today's Special</span>
                                </motion.div>
                              )}

                              <div className="text-sm text-gray-500 mt-1">{item.cuisine} Cuisine</div>
                              <div className="mt-2">
                                <p className="text-sm">
                                  <span className="text-gray-500 line-through">₹{inflatedPrice}</span>
                                  <span className="text-purple-600 font-bold ml-1"> ({discount * 100}% off)</span>
                                </p>
                                <span className="text-xl font-bold text-blue-600">₹{originalPrice}</span>
                              </div>
                              <div className="flex items-center space-x-2 mt-4">
                                <Star className="h-5 w-5 text-yellow-400 fill-current" />
                                <span className="text-sm text-gray-600">{item.rating}</span>
                                <Clock className="h-5 w-5 text-gray-400 ml-2" />
                                <span className="text-sm text-gray-600">{item.prepTime}</span>
                              </div>
                            </div>
                            <div className="text-right">
                              {/* This div is now empty as pricing info moved to left side */}
                            </div>
                          </div>
                        </CardHeader>
                      </Card>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );

  return (
    <animated.div
      className="min-h-screen bg-gray-100 relative"
      style={{
        background: 'linear-gradient(45deg, #f3f4f6, #e5e7eb)',
        ...backgroundProps,
      }}
    >
      <AnimatePresence>
        {viewOrderDetails ? (
          <OrderDetailsForm
            key="order-details"
            cartItems={cartItems}
            totalAmount={cartItems.reduce((total, item) => total + item.price * item.quantity, 0)}
            onPlaceOrder={placeOrder}
            fetchOrderStatus={fetchOrderStatus}
            goBack={() => setViewOrderDetails(false)}
            restaurantId={id}
            tableNumber={tableNumber}
          />
        ) : viewCart ? (
          <MyCart
            key="cart"
            cartItems={cartItems}
            updateCartItem={updateCartItem}
            onPlaceOrder={() => setViewOrderDetails(true)}
            setViewCart={setViewCart}
            userId={id}
            tableNumber={tableNumber}
            menuItems={restaurantData ? restaurantData.menu : []}
            addToCart={addToCart}
          />
        ) : isLoading ? (
          <motion.div
            key="loading"
            className="flex items-center justify-center h-screen bg-gray-100"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <Player autoplay loop src={loadingAnimation} style={{ height: '100px', width: '100px' }} />
          </motion.div>
        ) : isError ? (
          <motion.div
            key="error"
            className="flex items-center justify-center h-screen text-red-500"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            Restaurant not found
          </motion.div>
        ) : (
          <div key="main-content">{renderMainContent()}</div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showWaiterPopup && (
          <motion.div
            className="fixed inset-0 flex items-center justify-center bg-black/50 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="bg-white rounded-xl p-6 shadow-lg relative flex flex-col items-center"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
            >
              <motion.button
                onClick={() => setShowWaiterPopup(false)}
                className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <X size={20} />
              </motion.button>
              <Lottie
                loop
                animationData={waiterAnimation}
                play
                style={{ width: 150, height: 150 }}
              />
              <p className="text-lg font-semibold text-gray-800 mt-4">Your waiter is coming</p>
              <p className="text-sm text-gray-600 mt-2">Time remaining: {formatTime(timer)}</p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </animated.div>
  );
};

export default RestaurantApp;