import React, { useState, useEffect } from 'react';
import { Heart, Gift } from 'lucide-react';

const Lottery = ({ onClose }) => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [spinCount, setSpinCount] = useState(1);
  const [showMessage, setShowMessage] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const boxes = [
    { type: 'reward', text: 'Free Food', icon: Gift },
    { type: 'reward', text: 'Sweet Dessert', icon: Gift },
    { type: 'empty', text: 'Oops' },
    { type: 'empty', text: 'Oops' },
    { type: 'reward', text: 'Surprise', icon: Gift },
    { type: 'empty', text: 'Oops' },
  ];

  useEffect(() => {
    if (!isSpinning) return;

    let count = 0;
    const interval = setInterval(() => {
      setActiveIndex(prev => (prev + 1) % boxes.length);
      count++;

      if (count >= 28) {
        clearInterval(interval);
        setTimeout(() => {
          setIsSpinning(false);
          setShowMessage(true);
          const emptyIndices = boxes.reduce((acc, box, index) => {
            if (box.type === 'empty') acc.push(index);
            return acc;
          }, []);
          if (emptyIndices.length > 0) {
            const randomIndex = emptyIndices[Math.floor(Math.random() * emptyIndices.length)];
            setActiveIndex(randomIndex);
          }
        }, 500);
      }
    }, 250);

    return () => clearInterval(interval);
  }, [isSpinning]);

  const handleSpin = () => {
    if (spinCount > 0 && !isSpinning && !showMessage) {
      setIsSpinning(true);
      setSpinCount(prev => prev - 1);
    }
  };

  const handleBuySpins = (spins, cost) => {
    setSpinCount(prev => prev + spins);
  };

  return (
    <div className="flex items-center justify-center p-4 bg-gradient-to-br from-purple-900 via-red-800 to-pink-900">
      <div 
        className="relative bg-gray-900/90 backdrop-blur-xl rounded-2xl shadow-2xl p-6 border border-gray-800/50 overflow-hidden flex flex-col"
        style={{ height: '650px', width: '350px' }}
      >
        {/* Animated background effect */}
        <div className="absolute inset-0 bg-gradient-to-tr from-red-500/10 to-purple-500/10 animate-pulse" />

        {/* Header */}
        <div className="text-center relative z-10">
          <h2 className="text-3xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-red-400 to-pink-500 mb-4 animate-fade-in">
            Bihu Lottery
          </h2>
          <p className="text-gray-300 text-sm mb-2">Test Your Luck🤞</p>
        </div>

        {/* Boxes container */}
        <div className="grid grid-cols-2 gap-3 p-2 relative z-10 flex-grow">
          {boxes.map((box, index) => (
            <div
              key={index}
              className={`
                aspect-square rounded-xl p-4 flex flex-col items-center justify-center space-y-2
                transition-all duration-300 transform hover:-translate-y-1
                ${activeIndex === index 
                  ? 'bg-gradient-to-br from-red-500 to-pink-600 scale-105 shadow-xl shadow-red-500/30' 
                  : 'bg-gray-800/50 border border-gray-700/50'
                }
                ${box.type === 'reward' ? 'hover:bg-gray-700/70' : ''}
              `}
            >
              {box.type === 'reward' && (
                <Gift className={`
                  w-8 h-8 mb-2 transition-colors duration-300
                  ${activeIndex === index ? 'text-white' : 'text-red-400'}
                `} />
              )}
              <span
                className={`
                  text-sm font-medium text-center transition-colors duration-300
                  ${activeIndex === index ? 'text-white' : 'text-gray-300'}
                `}
              >
                {box.text}
              </span>
            </div>
          ))}
        </div>

        {/* Spin Button - Always in the DOM */}
        <div className="relative z-10 mt-6">
          <button
            onClick={handleSpin}
            className={`
              w-full py-4 px-6 rounded-xl text-white font-semibold text-lg
              bg-gradient-to-r from-red-500 to-pink-600
              hover:from-red-600 hover:to-pink-700
              transition-all duration-300 transform hover:scale-105
              flex items-center justify-center space-x-2
              disabled:opacity-50 disabled:cursor-not-allowed disabled:scale-100
              relative overflow-hidden group
            `}
            disabled={isSpinning || spinCount === 0 || showMessage}
          >
            <span className="absolute inset-0 bg-white opacity-0 group-hover:opacity-10 transition-opacity duration-300" />
            <Heart className="w-6 h-6 animate-pulse" />
            <span className="relative z-10">Spin Now</span>
          </button>
        </div>


      </div>
    </div>
  );
};

export default Lottery;