import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import QrScanner from "react-qr-scanner";
import { motion, AnimatePresence } from "framer-motion";
import { Camera, Instagram, MessageCircle, ChevronUp, Sparkles, Info } from "lucide-react";
import MainLogo from "../assets/MainLogo.svg";

const LandingPage = () => {
  const [isScanning, setIsScanning] = useState(true);
  const [scanResult, setScanResult] = useState(null);
  const navigate = useNavigate();

  // Dynamic greeting based on time of day
  const getGreeting = () => {
    const currentHour = new Date().getHours();
    if (currentHour < 12) return "☕ Good Morning";
    if (currentHour < 15) return "☀️ Good Noon";
    if (currentHour < 18) return "🌇 Good Afternoon";
    if (currentHour < 21) return "🌙 Good Evening";
    return "😴 Feeling Sleepy";
  };

  // Navigation handlers
  const handleSignUp = () => navigate("/restaurant-signup");
  const handleLogin = () => navigate("/restaurant-login");
  const handleAboutUs = () => navigate("/about-us");

  // QR Scanner handlers
  const startScan = () => {
    setIsScanning(true);
    setScanResult(null);
  };

  const handleScan = (data) => {
    if (data) {
      const scannedUrl = data.text;
      const urlParts = scannedUrl.split("/");
      const restaurantIndex = urlParts.findIndex((part) => part === "restaurant");

      if (restaurantIndex !== -1 && urlParts.length > restaurantIndex + 1) {
        const restaurantId = urlParts[restaurantIndex + 1];
        const tableNumber = urlParts.includes("table") ? urlParts[urlParts.length - 1] : null;

        if (tableNumber) {
          navigate(`/restaurant/${restaurantId}/table/${tableNumber}`);
        } else {
          navigate(`/restaurant/${restaurantId}`);
        }

        setIsScanning(false);
        setScanResult(restaurantId);
      } else {
        console.error("Invalid QR code format.");
      }
    }
  };

  const handleError = (err) => {
    console.error("QR Scanner Error:", err);
  };

  return (
    <motion.div
      className="min-h-screen bg-white flex flex-col items-center justify-between p-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      {/* Header */}
      <motion.div
        className="flex flex-col items-center mt-8"
        initial={{ y: -50 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <motion.img
          src={MainLogo}
          alt="DineQR Logo"
          className="w-20 h-20 rounded-full shadow-lg"
          whileHover={{ scale: 1.05 }}
        />
        <h1 className="mt-4 text-4xl font-bold text-gray-900">mywaiter.in</h1>
        <p className="mt-2 text-sm text-gray-500 flex items-center gap-2">
          <Sparkles size={16} className="text-yellow-400" />
          Scan. Order. Enjoy.
          <Sparkles size={16} className="text-yellow-400" />
        </p>
      </motion.div>

      {/* Main Content */}
      <motion.div
        className="flex-1 flex items-center justify-center w-full max-w-md"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        <div className="w-full bg-white/80 backdrop-blur-md rounded-2xl shadow-xl p-6">
          <p className="text-center text-lg font-medium text-gray-700 mb-4">{getGreeting()}</p>
          <AnimatePresence>
            {isScanning && (
              <motion.div
                className="relative w-full h-64 bg-gray-100 rounded-xl overflow-hidden"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                <QrScanner
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  constraints={{ video: { facingMode: "environment" } }}
                  style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "1rem" }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </motion.div>

      {/* Footer */}
      <motion.div
        className="w-full bg-white/90 backdrop-blur-md rounded-t-2xl shadow-lg p-4"
        initial={{ y: 50 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {/* Social Links */}
        <div className="flex justify-center space-x-6 mb-4">
          <motion.a
            href="https://www.instagram.com/mywaiter.in/"
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 bg-gray-100 rounded-full text-gray-600"
            whileHover={{ scale: 1.1, backgroundColor: "#e2e8f0" }}
            whileTap={{ scale: 0.9 }}
          >
            <Instagram size={20} />
          </motion.a>
          <motion.a
            href="https://wa.me/918472958581"
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 bg-gray-100 rounded-full text-gray-600"
            whileHover={{ scale: 1.1, backgroundColor: "#e2e8f0" }}
            whileTap={{ scale: 0.9 }}
          >
            <MessageCircle size={20} />
          </motion.a>
          <motion.button
            onClick={handleAboutUs}
            className="p-2 bg-gray-100 rounded-full text-gray-600"
            whileHover={{ scale: 1.1, backgroundColor: "#e2e8f0" }}
            whileTap={{ scale: 0.9 }}
          >
            <Info size={20} />
          </motion.button>
        </div>

        {/* Owner Options */}
        <motion.div
          className="flex justify-center space-x-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
        >
          <motion.button
            onClick={handleLogin}
            className="px-4 py-2 bg-indigo-600 text-white rounded-full text-sm font-medium"
            whileHover={{ scale: 1.05, backgroundColor: "#4f46e5" }}
            whileTap={{ scale: 0.95 }}
          >
            Login
          </motion.button>
          <motion.button
            onClick={handleSignUp}
            className="px-4 py-2 bg-white border border-gray-200 text-gray-900 rounded-full text-sm font-medium"
            whileHover={{ scale: 1.05, backgroundColor: "#f3f4f6" }}
            whileTap={{ scale: 0.95 }}
          >
            Sign Up
          </motion.button>
        </motion.div>
      </motion.div>

      {/* Styles */}
      <style>{`
        .scanner-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          border: 2px solid rgba(99, 102, 241, 0.5);
          border-radius: 1rem;
          box-shadow: 0 0 20px rgba(99, 102, 241, 0.3);
        }
      `}</style>
    </motion.div>
  );
};

export default LandingPage;